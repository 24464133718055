<template>
  <div class="container pd100">
    <div v-show='!loading' class="header mb20">
      <div class="header-block">
        <div class="header-block-item header-block-item--first">
          <div class="header-block-media">
            <van-image class="header-block-avatar" :src="userInfo.employee_avatar" alt="" />
          </div>
          <div style="">
            <div class="header-block-title">
              {{ userInfo.employee_name }}
            </div>
            <!-- <div v-if="userInfo.employee_level" class="header-block-level">Lv {{userInfo.employee_level}}</div> -->
            <div v-if="userInfo.employee_position" class="header-block-level">{{userInfo.employee_position}}</div>
            
          </div>
        </div>
        <div @click="toStudent" class="header-block-change">
          <span>学生中心</span>
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
    <div v-show='!loading' class="main">
      <div v-for="(value,key,index) in cardMap" :key="index">
        <div v-if="value.tools.length" class="group changy-group">
          <van-cell :value="value.name" class="mb10" />
          <van-grid :border="false" :column-num="4">
            <van-grid-item
              v-for="(item,i) in value.tools"
              :key="i"
              icon-prefix="icon"
              :icon="item.icon"
              :text="item.title"
              :to="item.type == 'campus_qrcode_scan'?'':item.route"
              :badge="item.badge"
              @click="wechatConfigSuccessCallback(item.type)"
              style="position: relative;"
            >
              <span class="user-grid-item-text" slot="text">
                <span>{{item.title}}</span>
                <div @click.stop v-if="item.type == 'student_feeds' || item.type == 'student_album'" class="user-grid-item-box">
                  <wx-open-launch-weapp
                  id="launch-btn"
                  :path='item.route'
                  username="gh_704767f97fd7"
                >
                  <script type="text/wxtag-template">
                    <div style='width:86px;height:86px;'></div>
                  </script>
                </wx-open-launch-weapp>
                </div>
              </span>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
      <div class="group about-group">
        <van-cell title="退出登录" @click="fedLogOut" is-link/>
      </div>  
    </div>
    <!-- 退出登录 -->
    <!-- <div class="groupF about-group">
      <van-button @click="fedLogOut" type="default">退出登录</van-button>
    </div> -->
    <!-- <div class="group about-group"> -->
      <!-- <van-button @click="fedLogOut" type="default">退出登录</van-button> -->
      <!-- <van-cell title="退出登录" @click="fedLogOut" is-link/> -->
    <!-- </div> -->
    <!-- 所属校区 -->
    <van-action-sheet
      v-model="studioListShow"
      :actions="studioList"
      description="所属校区"
    />
    <!-- 版权 -->
    <copyright v-show='!loading' />
    <!-- 底部导航栏 -->
    <Tabbar v-show='!loading' />
    <loadings :loading='loading' />
  </div>
</template>
<script>
import { Grid, GridItem, ActionSheet, Dialog } from 'vant'
import Loadings from '@/components/loading'
import Tabbar from '@/components/Tabbar'
import util from '@/common/util'
import Copyright from '@/components/Copyright'
import PageMixin from '@/mixins/page'
import { scanQRCode } from '@/common/wx'
import {
  saveToken
} from '@/utils/cache'

export default {
  name: 'teacher',
  components: {
    Tabbar:Tabbar,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [ActionSheet.name]: ActionSheet,
    [Dialog.name]: Dialog,
    loadings:Loadings,
    Copyright
  },
  filters: {
    formatYuan(value) {
      return parseInt(value).toFixed(2)
    }
  },
  computed: {
  },
  mixins: [PageMixin],
  data() {
    return {
      loading:false,
      userInfo: {},
      studentList: [],
      studioList: [],
      studioListShow: false,
      premiumList: [],
      mallCard: [],
      cardMap: {
        'crm_menu': { name: '招生服务', tools: [] },
        'teacher_menu': { name: '教师服务', tools: [] },
        'campus_menu': { name: '机构服务', tools: [] }
      },
      active: 0,
      balance: 0,
      freeze: 0,
      score: 0,
      totleConsumed: 0,
      count: {}, // 订单统计
      notice_count: null, // 消息统计
      tools: [
        { icon: 'SetOutline', title: '资金明细', path: '/cashlog' },
        { icon: 'star-o', title: '我的收藏', path: '/favgoods' },
        // {icon:'comment-o',title:'我的评价',path:'/favgoods'},
        { icon: 'coupon-o', title: '优惠券', path: '/coupon' },
        { icon: 'location-o', title: '收货地址', path: '/address-list' },
        { icon: 'setting-o', title: '设置', path: '/setting' }
      ],
      activeBar:4,
      topTitle:''
    }
  },

  created() {
    this.loading = true
    this.getProfileCampus()
  },
  methods: {
    getEmployee(){
      this.loading = true
      this.$api.employee_index().then(res => {
        this.loading = false
        this.userInfo = res.data.employee
        localStorage.setItem('employee_watermark',res.data.employee.employee_watermark)
        const center = res.data.campus_center
        center.crm_menu ? (this.cardMap['crm_menu'].tools = center.crm_menu.filter(item => item.show === 1).map(item => ({ ...item, badge: Number(item.count) || null }))) : []
        center.teacher_menu ? (this.cardMap['teacher_menu'].tools = center.teacher_menu.filter(item => item.show === 1).map(item => ({ ...item, badge: Number(item.count) || null }))) : []
        center.campus_menu ? (this.cardMap['campus_menu'].tools = center.campus_menu.filter(item => item.show === 1).map(item => ({ ...item, badge: Number(item.count) || null }))) : []
      })
    },
    getProfileCampus(){
      this.$api.profile_campus().then(res => {
        localStorage.setItem('profile_employee',res.data.employee)
        if(res.data.employee ==1){
          this.getEmployee()
        }else{
          this.$router.push({ path: '/user' })
        }
      })
    },
    onShowStudio() {
      this.studioListShow = !this.studioListShow
    },
    onSettingClick() {
      this.$router.push({ path: '/setting' })
    },
    toStudent(){
        localStorage.setItem('profile_employee',2)
        this.$router.push({ path: '/user' })
    },
    // handleRouterChange(e){
    //   if(e === 0){
    //     this.$router.push({path:'/home'})
    //   }else if(e === 1){
    //     this.$router.push({path:'/recruit',query:{appid:localStorage.getItem('appid')}})
    //   }else if(e === 2){
    //     this.$router.push({path:'/schedule'})
    //   }else if(e === 3){
    //     this.$router.push({path:'/mall'})
    //   }
    // },
    fedLogOut(){
      let that = this
      Dialog.confirm({
        title: '退出提示',
        message: '您确认退出登录?',
      })
        .then(() => {
          that.$api.auth_logout()
          that.$store.dispatch('user/fedLogOut',{})
          wx.closeWindow()
        })
        .catch(() => {
          // on cancel
        });
    },
    wechatConfigSuccessCallback(type) {
      if(type == 'campus_qrcode_scan'){
        scanQRCode(1, ['qrCode']).then(res => {
          console.log(res)
          if (res.resultStr) {
            this.$api.tools_scan({ scan_code: res.resultStr })
              .then(res => {
                if (res.data.code && res.data.code === 'success') {
                  this.$dialog.confirm({
                  // title: '标题',
                  confirmButtonText:'继续',
                    message: '扫码签到成功'
                  })
                    .then(() => {
                    // on confirm
                      // this.$router.back()
                      this.wechatConfigSuccessCallback('campus_qrcode_scan')
                    })
                    .catch(() => {
                    // on cancel
                    })
                } else if(res.data.code && res.data.code === 'signed'){
                  this.$dialog.confirm({
                  // title: '标题',
                  confirmButtonText:'继续',
                    message: `已签到 签到时间：${res.data.checkin_time}
                    `
                  })
                    .then(() => {
                    // on confirm
                      // this.$router.back()
                      this.wechatConfigSuccessCallback('campus_qrcode_scan')
                    })
                    .catch(() => {
                    // on cancel
                    })
                }else{
                  this.$dialog.confirm({
                  // title: '标题',
                    confirmButtonText:'继续',
                    message: '扫码签到失败'
                  })
                    .then(() => {
                    // on confirm
                      // this.$router.back()
                      this.wechatConfigSuccessCallback('campus_qrcode_scan')
                    })
                    .catch(() => {
                    // on cancel
                    })
                }
              })
              .catch(err => {
                console.error(err)
                this.$dialog.confirm({
                  // title: '标题',
                  confirmButtonText:'继续',
                  message: '扫码签到失败'
                })
                  .then(() => {
                    // on confirm
                    // this.$router.back()
                    this.wechatConfigSuccessCallback('campus_qrcode_scan')
                  })
                  .catch(() => {
                    // on cancel
                  })
              })
          }
        })
        .catch(err=>{
          console.log(err)
        })
      }
    }
  },
}
</script>
<style lang="less" scoped>

  .style-box() {
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
  }

  .header {
    box-sizing: border-box;
    width: 100%;
    height: 277px;
    color: #fff;
    padding: 0 15px;
    // background-color: #fe4140;
    padding-top: 70px;
    background: @linear-gradient-color;
    background-image: url('~@/assets/user_bg.png');
    background-size: 100% 100%;
    &-media {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
    }
    &-title {
      font-size: 18px;
    }
    &-setting {
      position: absolute;
      top: 30px;
      right: 30px;
      font-size: 24px;
    }
  }

  .header-block{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    overflow-x: auto;
    position: relative;
    &-item{
      position: relative;
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
    &-items{
      position: relative;
      margin-right: 10px;
      flex-shrink: 0;
    }
    &-media {
      width: 60px;
      height: 60px;
      overflow: hidden;
      border-radius: 50%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    &-avatar{
      width: 58px;
      height: 58px;
      overflow: hidden;
      border-radius: 50%;
    }
    &-medias {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 50%;
      position: relative;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-avatars{
      width: 38px;
      height: 38px;
      overflow: hidden;
      border-radius: 50%;
    }
    &-bg{
      position: absolute;
      top: 1px;
      left: 1px;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background: rgba(0,0,0,.5);
    }
    &-level{
      color:#fff;
      margin-top: 5px;
      font-family: monospace;
      font-size: 16px;
      font-weight: bold;
      background: #ecbe4b;
      padding: 0 7px;
      line-height: 1.2;
      border-radius: 5px;
      display: inline-block;
    }
    &-title {
      // width: 60px;
      // text-align: center;
      font-size: 18px;
      // line-height: 1.8;
      color: #fff;
      // background: rgba(0,0,0,.3);
      // overflow:hidden;
      // text-overflow:ellipsis;
      // white-space:nowrap;
      // word-wrap:normal;
      // border-radius: 10px;
      // margin-top: 3px;
      // padding: 0 5px;
    }
    &-titles {
      // position: absolute;
      // left: 0;
      // bottom: 0;
      width: 30px;
      text-align: center;
      font-size: 10px;
      line-height: 1.8;
      color: #fff;
      background: rgba(0,0,0,.3);
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
      word-wrap:normal;
      border-radius: 10px;
      margin-top: 3px;
      padding: 0 5px;
      span{
        // position: absolute;
        // left: 0;
        // bottom: 0;
        width: 30px;
        text-align: center;
        font-size: 10px;
        line-height: 1.8;
        color: #fff;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
        word-wrap:normal;
      }
    }
    &-change{
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 2px;
    }
  }
  .main {
    // padding:0 20px;
    margin-top: -140px;
  }
  .group {
    .style-box();
    margin: 0 15px 15px 15px;
    &-inner{
      padding:10px 0;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
    }
    &-item{
      flex: 1;
      text-align: center;
    }
  }
  .groupF {
    box-sizing: border-box;
    overflow: hidden;
    background: #fff;
    margin: 0 15px 15px 15px;
    &-inner{
      padding:10px 0;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
    }
    &-item{
      flex: 1;
      text-align: center;
    }
    .van-button--default{
      width: 100%;
    }
  }
  .balance-group {
    .style-box();
    .van-row {
      text-align: center;
    }
    .van-col {
      text-align: center;
    }
    &-title {
      font-size: 14px;
      font-weight: bold;
      color: #000;
      margin-bottom: 5px;
    }
    &-txt {
      font-size: 12px;
      color: #999;
    }
  }
  .changy-group {
    /deep/ .van-grid-item__icon{
      position: relative;
      font-size: 20px;
    }
    // .van-icon {
    //   font-size: 20px;
    // }
    // .van-col {
    //   margin-bottom:10px;
    // }
  }

  .user-gruop {
    &-name {
      // padding: 10px 0;
      color: #333;
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
  .user-section {
    .style-box();
    margin: 0 20px 15px 20px;
    &-title {
      font-size: 14px;
      color: #000;
      font-weight: bold;
    }
    &-hd {
      box-sizing: border-box;
      width: 100%;
      padding: 10px 0px;
      overflow: hidden;
      color: #323233;
      font-size: 14px;
      line-height: 24px;
      background-color: #fff;
      display: flex;
      align-items: center;
    }
    &-bd {
      box-sizing: border-box;
      width: 100%;
      padding: 15px 0;
    }
    &-grid {
      box-sizing: border-box;
      width: 20%;
      float: left;
      text-align: center;
      .van-icon {
        font-size: 24px;
        margin-bottom: 5px;
      }
      &-img {
        display: inline-block;
        width: 30px;
        height: 30px;
        // margin-bottom: 5px;
      }
      &-title {
        color: #333;
        font-size: 12px;
      }
    }
  }
  .user-grid-item-text{
    margin-top: 0.16rem;
    color: #646566;
    font-size: 0.25rem;
    line-height: 1.5;
    word-wrap: break-word;
  }
  .user-grid-item-box{
    position: absolute;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index: 100;
  }

</style>
<style lang="less">
  .user-main{
    .van-info{
      line-height:initial
    }
  }
</style>